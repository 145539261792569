import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OpdService } from 'app/services/opd.service';
import moment from 'moment';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { OpdCardStatusIds } from '../models/opd-card.enum';
import { OpdCardPaymentPayload, OpdCard_RELATION } from '../models/opd-card.model';
import { MasterService } from 'app/services/master.service';
import { AuthService } from 'app/services/_auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class OpdBillService extends OpdService {
  private currentUser: any;

  clinic$ = new BehaviorSubject<any>(null);

  opdCard$ = new BehaviorSubject<OpdCard_RELATION>(null);
  billItems$ = new BehaviorSubject<any>(null);
  finishOpdCard$ = new BehaviorSubject<any>(null);

  private channel: BroadcastChannel;
  notPaidCount$ = new BehaviorSubject<any>(null);
  billCount$ = this.notPaidCount$.asObservable();

  isLoading$ = new BehaviorSubject<boolean>(false);
  isToggleSidebar$ = new BehaviorSubject<boolean>(false);

  constructor(
    http$: HttpClient,
    private service: OpdService,
    private masterService: MasterService,
    private authService: AuthService
  ) {
    super(http$);
    this.currentUser = this.authService.currentUserValue;
    this.channel = new BroadcastChannel('bill_count');
    this.channel.onmessage = (event) => {
      this.notPaidCount$.next(event.data);
    };
  }

  _setFilter(obj) {
    delete obj.date_range;
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
  }

  setOpdCard(payload) {
    this.opdCard$.next(payload);
    this.setOpdBillItemList(payload);
  }

  setFinishedOpdCard(payload) {
    this.finishOpdCard$.next(payload);
  }

  setLoading(payload) {
    this.isLoading$.next(payload);
  }

  setToggleSideBar(value) {
    this.isToggleSidebar$.next(value);
  }

  clearSelectedOpdCard() {
    this.opdCard$.next(null);
    this.billItems$.next(null);
    this.setToggleSideBar(false);
    localStorage.removeItem('opdBill');
  }

  clearFinishedOpdCard() {
    this.finishOpdCard$.next(null);
  }

  setOpdBillItemList(opdCard: OpdCard_RELATION) {
    if (!opdCard) return;
    const obj = [
      ...opdCard.opd_card_operatives.map((item) => {
        let code = item.operative.code;
        return {
          name: item.operative.name,
          code: code === 'null' ? '' : code,
          amount: item.amount,
          unit_price: item.unit_price,
          price: item.price,
          discount: item.discount,
          discount_percent: item.discount_percent,
          total: item.total,
          is_course_item: item.is_course_item,
        };
      }),
      ...opdCard.opd_card_goods_masters.map((item) => {
        let code = item.goods_master.sku_code;
        return {
          name: item.goods_master.goods_category.id === 3 ? item.goods_master.trade_name : item.goods_master.name,
          code: code === 'null' ? '' : code,
          amount: item.amount,
          unit_price: item.unit_price,
          price: item.price,
          discount: item.discount,
          discount_percent: item.discount_percent,
          total: item.total,
          is_course_item: item.is_course_item,
        };
      }),
      ...opdCard.opd_card_doctor_fees.map((item) => {
        return {
          name: item.doctor_fee.name,
          code: null,
          amount: item.amount,
          unit_price: item.unit_price,
          price: item.price,
          discount: item.discount,
          discount_percent: item.discount_percent,
          total: item.total,
        };
      }),
    ];
    this.billItems$.next(obj);
    return obj;
  }

  async paymentOpdCard(p: { id: number; payload: OpdCardPaymentPayload }) {
    await lastValueFrom(this.service.updateOpdCard(p.id, p.payload));
    const resp = await lastValueFrom(this.service.finishOpdCard(p.id));
    this.setFinishedOpdCard(resp);
    this.getBillCount();
    this.clearSelectedOpdCard();
  }

  async setClinicData() {
    let resp = await lastValueFrom(this.masterService.clinic(this.currentUser.clinic_id));
    this.clinic$.next(resp);
  }

  async getBillCount() {
    const today = moment().format('YYYY-MM-DD');
    const filters = {
      start_date: today,
      end_date: today,
      current_opd_card_status_master_id: OpdCardStatusIds.WAIT_MEDICINE_ID,
    };
    let resp = await lastValueFrom(this.service.getOpdCount(filters));
    // for same tab
    this.notPaidCount$.next(resp.count);
    // for cross tab
    this.channel.postMessage(resp.count);
  }

  get getSelectedOpdCard() {
    return this.opdCard$;
  }

  get getBillItems() {
    return this.billItems$;
  }

  get getFinishOpdCard() {
    return this.finishOpdCard$;
  }

  get isLoading() {
    return this.isLoading$;
  }

  get getClinic() {
    return this.clinic$;
  }

  get isToggleSidebar() {
    return this.isToggleSidebar$;
  }
}
