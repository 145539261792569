export const locale = {
  lang: 'en',
  data: {
    LOGIN: {
      WELCOME_TEXT: 'Welcome to',
      WELCOME_DESC: 'Please sign-in to your account and start using the system',
      USERNAME: 'Username',
      EMAIL: 'Email',
      PASSWORD: 'Password',
      SIGN_IN: 'Sign In',
      SIGN_OUT: 'Sign Out',
      INVALID_LOGIN: 'The email or password you entered is not valid. Please double-check and try again.',
    },
    MENU: {
      DASHBOARD: 'Dashboard',
      MEDICAL_RECORD: {
        SECTION: 'Medical Record',
        REGISTER: 'Register',
        CUSTOMER_PATIENT: 'Customer / Patient',
        INFO: 'Customer Patient Info',
      },
      EXAMINATION_ROOM: 'Examination Room',
      QUEUE: 'Queue',
      APPOINTMENT: 'Appointment',
      REPORTS: {
        SECTION: 'Reports',
        INCOME_REPORT: 'Income Report',
        EXPENSE_REPORT: 'Expense Report',
        CASE_REPORT: 'Case Report',
        SSO_REPORT: 'SSO Report',
        CUSTOMER_REPORTS: {
          SECTION: 'Customer Reports',
          GROWTH_RATE_OVER_TIME: 'Customer Growth Rate Over Time',
          NUMBER_OF_NEW_CUSTOMER: 'Number of New Customers',
          NUMBER_OF_OLD_CUSTOMERS_RECEIVED_SERVICE_AGAIN: 'Number of Old Customers Received the Service Again',
        },
      },
      STOCK_MANAGEMENT: {
        SECTION: 'Stock Management',
        PURCHASE: 'Purchase',
        MEDICINE_AND_EQUIPMENT_LIST: 'Medicine / Equipment List',
        PRODUCT_LIST: 'Product List',
      },
      SETTINGS: {
        SECTION: 'Settings',
        GENERAL_INFORMATION: {
          SECTION: 'General Information',
          CLINIC_INFORMATION: 'Clinic Information',
          DOCTOR_INFORMATION: 'Doctor Information',
          EXAMINATION_ROOM: 'Examination Room',
          USER: 'User',
          ROLE_AND_PERMISSION: 'Role & Permission',
          LINE_NOTIFICATION: 'LINE Notification',
        },
        PRODUCT_AND_SERVICE: {
          SECTION: 'Product & Service',
          MEDICINE_AND_EQUIPMENT: {
            SECTION: 'Medicine / Equipment',
            MEDICINE_AND_EQUIPMENT_LIST: 'Medicine / Equipment List',
            MEDICINE_PACK: 'Medicine Pack',
            MEDICINE_AND_EQUIPMENT_CATEGORY: 'Medicine / Equipment Category',
            MEDICINE_AND_EQUIPMENT_TYPE: 'Medicine / Equipment Type',
          },
          PROCEDURE_AND_FEE: {
            SECTION: 'Procedure / Doctor Fee',
            MEDICAL_PROCEDURE_LIST: 'Medical Procedure List',
            MEDICAL_PROCEDURE_CATEGORY: 'Medical Procedure Category',
            DOCTOR_FEE: 'Doctor Fee',
          },
          PRODUCT: {
            SECTION: 'Product',
            PRODUCT_LIST: 'Product List',
            PRODUCT_CATEGORY: 'Product Category',
          },
          STOCK: {
            SECTION: 'Stock',
            IMPORT_STOCK_LIST: 'Import Stock',
            EXPORT_STOCK_LIST: 'Export Stock',
          },
          COURSE: {
            SECTION: 'Courses',
            // IMPORT_STOCK_LIST: 'รายการสินค้านำเข้า',
            // EXPORT_STOCK_LIST: 'รายการสินค้านำออก',
          },
        },
        DOCUMENT: {
          SECTION: 'Document',
          MEDICAL_CERTIFICATE: 'Medical Certificate',
        },
        PERMISSION: {
          SECTION: 'Permission Management',
          // CLINIC_INFORMATION: 'Clinic Information',
          // DOCTOR_INFORMATION: 'Doctor Information',
          // EXAMINATION_ROOM: 'Examination Room',
          // USER: 'User',
          // ROLE_AND_PERMISSION: 'Role & Permission',
          // LINE_NOTIFICATION: 'LINE Notification',
        },
      },
      POS: 'POS',
      QUEUE_DISPLAY: 'Queue Display',
    },
    TITLE: {
      CUSTOMER_PATIENT_LIST: 'Customer / Patient List',
      CUSTOMER_PATIENT_REGISTER: 'Customer / Patient Register',
      CUSTOMER_PATIENT_DETAIL: 'Customer / Patient Detail',
      EDIT_CUSTOMER_PATIENT_INFORMATION: 'Edit Customer / Patient Information',
      OPD_HISTORY: 'OPD History',
      OPERATION_RECORD: 'Operation Record',
      TREATMENT_MEDICATION_LIST: 'Treatment and Medication List',
      RECEIPT: 'Receipt',
      RECEIPT_TITLE: 'Receipt',
      MEDICAL_CERTIFICATE: 'Medical Certificate',
      MEDICAL_CERTIFICATE_HISTORY: 'Medical Certificate History',
      NEW_OPD_CARD: 'New OPD Card',
      EXAMINATION_ROOM_LIST: 'Examination Room List',
      APPOINTMENT_LIST: 'Appointment List',
      OPD_CARD: 'OPD Card',
      COMPARE_HISTORY: 'Compare History',
      INCOME_REPORT: 'Income Report',
      EXPENSE_REPORT: 'Expense Report',
      CASE_REPORT: 'Case Report',
      SETTING_CLINIC_INFORMATION: 'Setting Clinic Information',
      SETTING_EXAMINATION_ROOM: 'Setting Examination Room',
      SETTING_USER: 'Setting User',
      SETTING_MEDICINE_EQUIPMENT: 'Setting Medicine Equipment',
      NEW_MEDICINE_EQUIPMENT: 'New Medicine Equipment',

      MEDICINE_EQUIPMENT_INFORMATION: 'Medicine Equipment Information',
      SETTING_MEDICINE_PACK: 'Setting Medicine Pack',
      SETTING_MEDICINE_EQUIPMENT_CATEGORIES: 'Setting Medicine Equipment Categories',
      SETTING_MEDICINE_EQUIPMENT_TYPE: 'Setting Medicine Equipment Type',
      SETTING_MEDICAL_PROCEDURE: 'Setting Medical Procedure',
      NEW_MEDICAL_PROCEDURE: 'New Medical Procedure',
      MEDICAL_PROCEDURE_INFORMATION: 'Medical Procedure Information',
      SETTING_MEDICAL_PROCEDURE_CATEGORIES: 'Setting Medical Procedure Categories',
      SETTING_DOCTOR_FEE: 'Setting Doctor Fee',
      SETTING_PRODUCT: 'Setting Product',
      SETTING_PERMISSION: 'Setting Permission',
      SETTING_LIST_PERMISSION: 'List Permission',
      NEW_PRODUCT: 'New Product',
      PRODUCT_INFORMATION: 'Product Information',
      SETTING_PRODUCT_CATEGORIES: 'Setting Product Categories',
      EXAMINED_PATIENTS: 'Examined Patients',
      EXAMINATION_ROOM_INFO: 'Customer Patient Info',
      SETTING_IMPORT_STOCK: 'Import Stock',
      SETTING_EXPORT_STOCK: 'Export Stock',
      SETTING_COURSES: 'Courses',
      NEW_COURSE: 'New Course',
      EDIT_COURSE: 'Edit Course',
      SETTING_COURSE: 'Setting Course',
      COURSE_INFORMATION: 'Course Information',
    },
    SWEETALERT: {
      CONFIRMATION_TITLE: 'Confirmation',
      CONFIRMATION_TEXT: 'Are you sure you want to proceed this?',
      CONFIRMATION_DELETE_TEXT: 'Are you sure you want to delete this?',
      CREATE_AN_ACCOUNT: 'Create An Account',
      CREATE_AN_ACCOUNT_TEXT: 'Are you sure to create this account?',
      CREATE_AN_ACCOUNT_SUCCESS: 'An account has been created',
      UPDATE_AN_ACCOUNT: 'Update An Account',
      UPDATE_AN_ACCOUNT_TEXT: 'Are you sure to update this account?',
      NEW_CUSTOMER_PATIENT_REGISTER: 'New Customer / Patient',
      NEW_CUSTOMER_PATIENT_REGISTER_TEXT: 'Are you sure you want to confirm registration?',
      NEW_CUSTOMER_PATIENT_REGISTER_SUCCESS: 'Successfully registered',
      UPDATE_CUSTOMER_PATIENT: 'Update Information',
      NEW_OPD_CARD_TITLE: 'New OPD Card',
      NEW_OPD_CARD_TEXT: 'Are you sure you need to create an OPD card?',
      NEW_OPD_CARD_SUCCESS: 'The OPD card has been created. 📋',
      FINISH_OPD_CARD_TITLE: 'Complete OPD Card',
      FINISH_OPD_CARD_TEXT: 'Are you sure you need to complete the OPD card?',
      SAVE_AND_FINISH_OPD_CARD_TITLE: 'Save and Complete OPD Card',
      SAVE_FINISH_OPD_CARD_TEXT: 'Are you sure you need to save and complete the OPD card?',
      FINISH_OPD_CARD_SUCCESS: 'The OPD card has been completed. 📋',
      CANCEL_OPD_CARD_TITLE: 'Cancel OPD Card',
      CANCEL_OPD_CARD_TEXT: 'Are you sure you need to cancel the OPD card?',
      CANCEL_OPD_CARD_SUCCESS: 'The OPD card has been canceled.',
      SAVE_MEDICAL_CERT_TITLE: 'Medical Certificate',
      SAVE_MEDICAL_CERT_TEXT: 'Are you sure you need to save a medical certificate?',
      SAVE_MEDICAL_CERT_SUCCESS: 'The medical certificate has been created. 📋',
      UPDATE_CLINIC: 'Update Clinic Information',
      NEW_MEDICINE_REGISTER: 'New Medicine / Equipment',
      NEW_PRODUCT_REGISTER: 'New Product',
      CREATE_DATA: 'Create Data',
      CREATE_DATA_TEXT: 'Are you sure to create this data?',
      UPDATE_DATA: 'Update Data',
      UPDATE_DATA_TEXT: 'Are you sure to update this data?',
      SUCCESS_TITLE: 'Success',
      SUCCESS_TEXT: 'New information has been created. 😀',
      SUCCESS_TEXT_UPDATE: 'We have successfully updated the information. 😀',
      SUCCESS_TEXT_DELETE: 'We have successfully deleted this information. 🗑️',
      ERROR_TITLE: 'Oops',
      ERROR_TEXT: 'Sorry, something went wrong. 😕',
      ERROR_TEXT_LIMIT_DOCTOR: 'The activation limit for Doctor role has been reached. 😕',
      ERROR_TEXT_LIMIT_ADMIN: 'The activation limit for Administrator role has been reached. 😕',
      ERROR_TEXT_LIMIT_STAFF: 'The activation limit for Staff role has been reached. 😕',
      ERROR_CN: 'This CN has already been taken.',
      WARNING_TITLE: 'Warning',
      WARNING_TEXT: 'Something is not right, It may prevent you from continuing. ⛔',
      WARNING_IMAGE_SIZE_TITLE: 'Image is too large',
      WARNING_IMAGE_SIZE_TEXT: 'Please make sure the image size is not larger than {{value}} {{unit}}',
      PAYMENT_SUCCESS: 'Payment Success',
      MEDICINE_MASTER_REQUIRED: 'If there is no "Category" or "Type" data, please add it before creating a list.',
      PROCEDURE_MASTER_REQUIRED: 'If there is no "Category" data, please add it before creating a list.',
      COURSE_MASTER_REQUIRED: 'If there is no "Course" data, please add it before creating a list.',
      PRODUCT_MASTER_REQUIRED: 'If there is no "Category" data, please add it before creating a list.',
      RE_LOGIN_FOR_PERMISSION: 'Please notify users to re-login again to update their permission.',
      SELECT_MEDICAL_CERT: 'Select Medical Certificate to display information.',
      SELECT_OPD_BILL: 'Select OPD bill to display information.',
      SELECT_PATIENT_COURSE: 'Select Patient Course to display information.',
      ADDED_APPOINTMENT: 'Appointment has been successfully saved. 🗓️',
      ADD_COURSE: 'Add Course Item',
    },
    STATUS: {
      ALL: 'All',
      COMPLETED: 'Completed',
      CANCEL: 'Cancel',
      BEING_EXAMINED: 'Being Examined',
      WAITING_FOR_EXAMINATION: 'Waiting For Examination',
      ACTIVE: 'Active',
      INACTIVE: 'Inactive',
    },
    VALIDATOR: {
      REQUIRED: 'Field cannot be blank',
      MUST_BE_AT_LEAST_X_CHARACTERS: 'Must be at least {{value}} characters',
      MUST_CONTAIN_AT_LEAST_X_LETTER_IN_LOWER_CASE: 'Must contain at least {{value}} letter in lower case',
      MUST_CONTAIN_AT_LEAST_X_LETTER_IN_UPPER_CASE: 'Must contain at least {{value}} letter in upper case',
      MUST_CONTAIN_AT_LEAST_X_NUMBER: 'Must contain at least {{value}} number',
      PLEASE_SELECT_YOUR_ROLE: 'Please select your role',
      INVALID_EMAIL: 'Please enter a valid email address',
      PASSWORD_MISMATCH: "Those passwords didn't match",
      PASSWORD_INVALID: "Invalid 'Password' pattern.",
      PLEASE_SELECT: 'Please select',
      PLEASE_SELECT_ROOM: 'Please select an examination room.',
      PLEASE_SELECT_DOCTOR: 'Please select the responsible doctor.',
      REQUIRED_LABEL: 'Please add label',
      INVALID_PAY_AMOUNT: 'Invalid pay amount',
      PLEASE_ADD_ITEM: 'Pleas Add Item',
    },
    APP: {
      ATTACHMENTS: 'Attachments',
      FILE_PLACEHOLDER_2: 'Drag and drop file here. Or',
      SEARCH: 'Search',
      SEARCH_PLACEHOLDER: 'Search...',
      INLINE_SEARCH: 'Search:',
      DATE_RANGE: 'Date Range',
      INLINE_DATE_RANGE: 'Date Range:',
      INLINE_DATE: 'Date:',
      INLINE_MONTH: 'Month:',
      INLINE_YEAR: 'Year:',
      INLINE_FILE_EXAMINATION: 'Examination File:',
      GENERAL_INFORMATION: 'General Information',
      CN: 'CN',
      CID: 'CID',
      ID_CARD_NO: 'ID Card No.',
      NAME: 'Name',
      NAME_TH: 'Name (TH)',
      LASTNAME_TH: 'Last Name (TH)',
      NAME_EN: 'Name (EN)',
      LASTNAME_EN: 'Last Name (EN)',
      NAME_TITLE: 'Title',
      BIRTHDAY: 'Birthday',
      BIRTHDAY_FORMAT: 'Day-Month-Year',
      AGE: 'Age',
      YEAR: 'Yrs.',
      MONTH: 'Mths.',
      DAY: 'Dys.',
      BLOOD_GROUP: 'Blood Group',
      GENDER: 'Gender',
      MARITAL_STATUS: 'Marital Status',
      RACE: 'Race',
      CAREER: 'Career',
      EDUCATION: 'Education',
      MALE: 'Male',
      FEMALE: 'Female',
      ACCIDENT_AND_SURGERY: 'Accident And Surgery',
      EPILEPSY: 'Epilepsy',
      CONGENITAL_DISEASE: 'Congenital Disease',
      REGULAR_MEDICATION: 'Regular Medication',
      SMOKING_HISTORY: 'Smoking History',
      NO_SMOKING: 'No Smoking',
      SMOKE_SOMETIMES: 'Sometimes',
      SMOKING: 'Smoking',
      ALCOHOL_CONSUMPTION_HISTORY: 'Alcohol Consumption History',
      DONT_DRINK_ALCOHOL: "Don't Drink Alcohol",
      DRINK_ALCOHOL_SOMETIMES: 'Sometimes',
      DRINK_ALCOHOL: 'Drink Alcohol',
      ALLERGY_INFORMATION: 'Allergy Information',
      NO_HISTORY_OF_DRUG_ALLERGY: 'No History',
      DONT_KNOW: "Don't Know",
      DRUG_ALLERGY: 'Allergy',
      ALLERGIC_DRUG_NAME: 'Allergic Drug Name',
      SYMPTOMS_WHEN_ALLERGIC_TO_DRUGS: 'Symptoms When Allergic',
      ALLERGIC_DRUG_GROUP: 'Allergic Drug Group',
      ADDRESS: 'Address',
      ADDRESS_INFORMATION: 'Address Information',
      PHONE: 'Phone',
      PHONE_NO: 'Phone {{no}}',
      PHONE_1: 'Phone 1',
      PHONE_2: 'Phone 2',
      LINE_ID: 'LINE ID',
      EMAIL: 'Email',
      CONTACT_NUMBER: 'Contact Number',
      CONTACT_NAME: 'Contact Name',
      RELATIONSHIP: 'Relationship',
      EMERGENCY_CASE: 'Emergency Case',
      ADDRESS_ACCORDING_TO_ID_CARD: 'Address According to ID Card',
      CURRENT_ADDRESS: 'Current Address',
      COPY_FROM_ID_CARD: 'Copy From ID Card',
      ADDRESS_NO: 'Address No.',
      VILLAGE_NO: 'Village No.',
      VILLAGE_BUILDING: 'Village / Building',
      ALLEY: 'Alley',
      ALLEY_PREFIX_1: '',
      ROAD: 'Road',
      SUB_DISTRICT: 'Sub-District',
      SUB_DISTRICT_PREFIX_1: '',
      SUB_DISTRICT_PREFIX_2: '',
      DISTRICT: 'District',
      DISTRICT_PREFIX_1: '',
      DISTRICT_PREFIX_2: '',
      PROVINCE: 'Province',
      POST_CODE: 'Post Code',
      MEDICAL_TREATMENT_RIGHTS: 'Medical Treatment Rights',
      TREATMENT_RIGHTS: 'Treatment Rights',
      HOSPITAL: 'Hospital',
      OPD_HISTORY: 'OPD History',
      HISTORY: 'History',
      RECEIPT: 'Receipt',
      MEDICAL_CERTIFICATE: 'Medical Certificate',
      NO_DATA: 'No Data',
      SERVICE_DATE: 'Service Date',
      OPD_CARD_REQUEST: 'OPD Card Request',
      EXAMINATION_ROOM: 'Examination Room',
      RESPONSIBLE_DOCTOR: 'Responsible Doctor',
      SAVED_BY: 'Saved by',
      WEIGHT_LABEL: 'Weight (WT)',
      HEIGHT_LABEL: 'Height (HT)',
      BMI_LABEL: 'Body Mass Index (BMI)',
      BODY_TEMP_LABEL: 'Temperature (BT)',
      PULSE_LABEL: 'Pulse Rate (PR)',
      BP_LABEL: 'Blood Pressure (BP)',
      RR_LABEL: 'Respiratory Rate (RR)',
      FBS_LABEL: 'Fasting Blood Sugar (FBS)',
      CC_LABEL: 'CC:',
      HPI_LABEL: 'HPI:',
      PMH_LABEL: 'PMH:',
      PE_LABEL: 'PE:',
      DX_LABEL: 'Dx:',
      TX_LABEL: 'Tx:',
      UNIT_KG: 'kg.',
      UNIT_CM: 'cm.',
      UNIT_CELSIUS: '°C',
      UNIT_PER_MIN: '/min',
      CC: 'Chief Complaint',
      HPI: 'History of Present Illness',
      PMH: 'Past Medical History',
      ADD_NOTE: 'Additional Note',
      PHYSICAL_EXAMINATION: 'Physical Examination',
      DIAGNOSIS_AND_TREATMENT: 'Diagnosis And Treatment',
      APPOINTMENT_RECORD: 'Appointment',
      APPOINTMENT_DATE: 'Appointment Date',
      APPOINTMENT_NOTE: 'Appointment Note',
      DOC_NO: 'Doc No.',
      ISSUE_DATE: 'Issue Date',
      CUSTOMER: 'Customer',
      PAYMENT_DETAILS: 'Payment Details',
      PAYMENT_METHODS: 'Payment Methods',
      ACCOUNT_NUMBER: 'Account Number',
      BANK: 'Bank',
      ACCOUNT_NAME: 'Account Name',
      ACCOUNT_NO: 'Account No.',
      USED_FOR: 'Used For',
      SALESPERSON: 'Salesperson',
      BOOK_NO: 'Book No.',
      OPD_REFERENCE: 'OPD Reference',
      EXAMINATION_PLACE: 'Examination Place',
      EXAMINATION_DATE: 'Examination Date',
      EXAMINATION_DOCTOR: 'Examination Doctor',
      MEDICAL_PROFESSIONAL_LICENSE_NO: 'Medical Professional License No.',
      MEDICAL_LOCATION: 'Medical Location',
      NAME_OF_EXAMINEE: 'Name of Examinee',
      ADDRESS_PLACE: 'Address',
      CAN_BE_CONTACTED: 'Can be Contracted',
      DOC_CONGENITAL_DISEASE: '1. Congenital Disease',
      DOC_ACCIDENT_AND_SURGERY: '2. Accident And Surgery',
      DOC_HOSPITALIZED: '3. Hospitalized',
      HOSPITALIZED: 'Hospitalized',
      DOC_EPILEPSY: '4. Epilepsy',
      DOC_OTHER_IMPORTANT_HISTORY: '5. Other Important History',
      GENERAL_PHYSICAL_CONDITION: 'General Physical Condition',
      APPEARING_SYMPTOMS_OR_OTHER_DISEASES: 'Appearing Symptoms or Other Diseases',
      DOCTOR_ADVICE: "Doctor's Advice",
      ADD_TO_EXAMINATION_ROOM: 'Add to Examination Room',
      OPTIONAL: 'Optional',
      PLACEHOLDER_FILL: '- Fill -',
      TO: 'To',
      STATUS: 'Status',
      PHONE_AND_EMERGENCY: 'Phone / Emergency',
      HAVE_AN_APPOINTMENT: 'Have an Appointment',
      NO_APPOINTMENT: 'No Appointment',
      PROCEDURE: 'Procedure',
      MEDICINE_AND_EQUIPMENT: 'Medicine / Equipment',
      MEDICINE_PACK: 'Medicine Pack',
      DOCTOR_FEE: 'Doctor Fee',
      CATEGORY: 'Category',
      TYPE: 'Type',
      MEDICINE: 'Medicine',
      EQUIPMENT: 'Equipment',
      PRODUCT: 'Product',
      MEDICINE_LABEL: 'Medicine Label',
      MEDICINE_LABEL_DETAIL: 'Medicine Label Detail',
      MEDICINE_LABEL_PREVIEW: 'Medicine Label Preview',
      MEDICINE_NAME: 'Medicine Name',
      USAGE_UNIT: 'Usage Unit',
      DISPENSE_UNIT: 'Dispense Unit',
      USAGE: 'Usage',
      FREQUENCY: 'Frequency',
      TIME: 'Time',
      EXPIRED: 'Expired',
      UNIT: 'Unit',
      DAYS: 'Days',
      PERIOD: 'Period',
      DISPENSE_AMOUNT: 'Dispense Amount',
      INDICATION: 'Indication',
      CAUTION: 'Caution',
      DETAIL_ADVISE: 'Detail / Advise',
      DETAIL_ADVISE_ADDON: 'How many days off work - why?',
      DIAGNOSE: 'Diagnose',
      DATE: 'Date',
      DOCTOR: 'Doctor',
      SELECT_DOCTOR: 'Select Doctor',
      STAFF: 'Staff',
      SELECT_STAFF: 'Select Staff',
      OPD_CARD: 'OPD Card',
      YES: 'Yes',
      NO: 'No',
      NORMAL: 'Normal',
      ABNORMAL: 'Abnormal',
      ANOMALY: 'Anomaly',
      MAIN_LOGO: 'Main Logo',
      POS_LOGO: 'POS Logo',
      IMAGE_SIZE_LIMIT_MB: 'Image size limit {{value}} MB.',
      IMAGE_SIZE_LIMIT: 'Image size limit {{value}} {{unit}}.',
      CLINIC_CODE: 'Clinic Code',
      CLINIC_NAME: 'Clinic Name',
      LICENSE_NO: 'License No.',
      TYPE_OF_SERVICE: 'Type of Service',
      TAX_NO: 'TAX NO',
      FAX: 'FAX',
      PAYMENT_INFORMATION: 'Payment Information',
      ROLE: 'Role',
      SHORT_NOTE: 'Short Note',
      MEDICINE_USAGE: 'Usage',
      BAHT: 'Baht',
      PERCENT: '%',
      NEW_APPOINTMENT: 'New Appointment',
      APPOINTMENT: 'Appointment Infomation',

      //BOARD
      EMPTY_DOC: 'Empty Document',
      CERTIFICATE_TYPE: 'Certificate Type',

      // REPORTS
      DATE_PERIOD: 'Date',
      MONTH_PERIOD: 'Month',
      TOTAL_INCOME: 'Total Income',
      PROCEDURES: 'Procedures',
      DOCTOR_FEES: 'Doctor Fees',
      MEDICINE_EQUIPMENTS: 'Medicines / Equipments',
      MEDICINES: 'Medicines',
      EQUIPMENTS: 'Equipments',
      OTHER_PRODUCTS: 'Products',
      TOTOL_CASES: 'Total Cases',
      OLD_PATIENTS: 'Old Patients',
      NEW_PATIENTS: 'New Patients',
      APPOINTMENTS: 'Check Appointments',
      FILTERS: 'Filters',
      CUSTOMER_PATIENTS: 'Customer / Patient',
      COURSES: 'Courses',
      PATIENT_COURSES: 'Patient Course',

      // SETTINGS
      ENABLE: 'Enable',
      DESCRIPTION: 'Description',
      TYPE_NAME: 'Type Name',
      CATEGORY_NAME: 'Category Name',
      MEDICINE_LIST: 'Medicine List',
      MEDICINE_PACK_NAME: 'Medicine Pack Name',
      MEDICINE_CATEGORY: 'Medicine Category',
      MEDICINE_TYPE: 'Medicine Type',
      EQUIPMENT_CATEGORY: 'Equipment Category',
      EQUIPMENT_TYPE: 'Equipment Type',
      TRADE_NAME: 'Trade Name',
      GENERIC_NAME: 'Generic Name',
      GENERIC_NAME_ADDON: 'Generic Name (For display on drug labels)',
      CODE: 'Code',
      BARCODE: 'Barcode',
      LICENSEE: 'Licensee',
      ACTING_TYPE: 'Acting Type',
      DETAIL: 'Detail',
      UNITS: 'Units',
      UNIT_NAME: 'Unit Name',
      MULTIPILIER: 'Amt./Unt.',
      AMOUNT: 'Amount',
      MEDICINE_LABEL_DETIAL: 'Medicine Label Information',
      DOCTOR_FEE_NAME: 'Doctor Fee Name',
      DOCTOR_LIST: 'Doctor List',
      GROUP: 'Group',
      NEW_PAYMENT_METHOD: 'New Payment Method',
      PAYMENT_METHOD_INFORMATION: 'Payment Method Information',
      NEW_EXAMINATION_ROOM: 'New Examination Room',
      EXAMINATION_ROOM_INFORMATION: 'Examination Room Information',
      NEW_USER: 'New User',
      USER_INFORMATION: 'User Information',
      ACCOUNT_AND_PASSWORD: 'Account and Password',
      PASSWORD: 'Password',
      CONFIRM_PASSWORD: 'Confirm Password',
      NEW_MEDICINE_PACK: 'New Medicine Pack',
      MEDICINE_PACK_INFORMATION: 'Medicie Pack Information',
      NEW_MEDICINE_EQUIPMENT_CATEGORIES: 'New Medicine Equipment Category',
      MEDICINE_EQUIPMENT_CATEGORIES_INFORMATION: 'Medicine Equipment Category Information',
      NEW_MEDICINE_EQUIPMENT_TYPE: 'New Medicine Equipment Type',
      MEDICINE_EQUIPMENT_TYPE_INFORMATION: 'Medicie Equipment Type Information',
      MEDICAL_PROCEDURE_INFORMATION: 'Medical Procedure Information',
      MEDICAL_PROCEDURE_NAME: 'Medical Procedure Name',
      TIMES: 'Times',
      PER_TIME: 'Times',
      SELL_PRICE: 'Sell Price',
      MEDICINE_EQUIPMENT_PRICE_PER_TIME: 'Med./Equip. (/Time)',
      MEDICINE_AND_EQUIPMENT_LIST: 'Medicine / Equipment List',
      MEDICINE_AND_PRODUCT_AND_PROCEDURE_LIST: 'Medicine / Product / Equipment / Procedure List',
      NEW_MEDICINE_EQUIPMENT: 'New Medicine Equipment',
      NEW_MEDICINE_EQUIPMENT_PROCEDURE: 'New Medicine / Product / Equipment / Procedure',
      USES_AMOUNT_PER_TIME: 'Uses/Times',
      PRICE_PER_UNIT: 'Price/Unit',
      SUB_TOTAL: 'Total Price',
      NEW_MEDICAL_PROCEDURE_CATEGORIES: 'New Medical Procedure Category',
      MEDICAL_PROCEDURE_CATEGORIES_INFORMATION: 'Examination Room Information',
      NEW_DOCTOR_FEE: 'New Doctor Fee',
      DOCTOR_FEE_INFORMATION: 'Doctor Fee Information',
      PRODUCT_CATEGORY: 'Product Category',
      NEW_PRODUCT_CATEGORIES: 'New Product Category',
      PRODUCT_CATEGORIES_INFORMATION: 'Product Category Information',
      LIST_MEDICINE_ALLERGY_INFORMATION: 'Therer are {{amount}} medicine allergy information',
      LOADING: 'Loading...',
      NONE: 'None',
      BEFORE_DISCOUNT: 'Before Discount',
      VN: 'VN',
      EXAMINATION_FILE: 'Examination File',
      NO_ATTACH_FILE: 'No Attach File',
      MODULES: 'Modules',
      EDIT_MODE: 'Editing',
      VIEW_MODE: 'Mode',
      SEARCH_BY_NAME_CN: 'Search by Name or CN',

      REQ_STOCK_IMPORT: 'Request Stock Import',
      REQ_STOCK_EXPORT: 'Request Stock Export',
      STOCK_IMPORT: 'Stock Import',
      STOCK_EXPORT: 'Stock Export',
      NEW_STOCK_IMPORT: 'New Stock Import',
      NEW_STOCK_EXPORT: 'New Stock Export',
      DOCUMENT_STOCK_DESCRIPTION: 'Description',
      EXPORT_REASON: 'Export Reason',

      //Dashboard
      PUT_OFF: 'Put Off',
      TODAY: 'Today',
      IN_ADVANCE: 'In Advance',
      SEVEN_DAY: '7 Day',
      FIFTEEN_DAY: '15 Day',
      THIRTY_DAY: '30 Day',
      WEEK: 'Week',
      PLEASE_SELECT_LIST_TO_DISPLAY: 'Select an item to display the chart.',
      PLEASE_SELECT_LIST: 'Select Item',
      DISPLAY_CHART: 'Display Data',

      PRODUCT_LIST: 'Product List',
      PRODUCT_NAME: 'Product Name',

      //Course
      COURSE_INFORMATION: 'Course Information',
      COURSE__NAME: 'Course Name',
      SELL_COMMISSION: 'Sell Commission',
      PROCEDURE_COMMISSION: 'Procedure Commission',
      COURSE_DOCTOR_FEE: 'Doctor Fee',
      POST_OPERATIVE_CARE: 'Post Operative Care',
      DURATION_MON: 'Duration Month',
      COURSE_PRICE_PER_TIME: 'Course price per time',

      UNIT_UNDEFINED: 'Unit Undefined',

      PAYMENT_TYPE: 'Payment Type',
      DEPOSIT_PAY: 'Deposit',
      INSTALLMENTS_PAY: 'Installment',
      REMAINING_BALANCE_PAY: 'Remaining Balance',
      USE_AMOUNT_AVAILABLE: 'Use amount available',
      USED_AMOUNT: 'Used amount',
      PRICE: 'Price',
      DISCOUNT: 'Discount',

      NEW_PATIENT_COURSE: 'New Patient Course',
      BUY_COURSE_DATE: 'Buy Date',
      START_COURSE_DATE: 'Start Date',
      EXPIRE_COURSE_DATE: 'Expire Date',
      WAIT_FOR_PAYMENT: 'Wait for payment',

      GENERAL_OPD: 'General',
      COURSE_OPD: 'Course',
      IMPORT_FROM_COURSE: 'Course Imported',
      OPD_NET_PRICE_REMARK: 'Items from course will not be included.',
    },
    COMPONENT: {
      REGISTER: 'Register',
      IMPORT_FILE_CSV: 'Import (CSV)',
      BROWSE_FILE: 'Browse File',
      DETAIL: 'Detail',
      EDIT: 'Edit',
      EDIT_DETAIL: 'Edit Information',
      DELETE: 'Delete',
      READ_FROM_ID_CARD: 'Read From ID Card',
      SAVE: 'Save',
      SAVE_CHANGES: 'Save Changes',
      SAVE_AND_COMPLETE: 'Save and Complete',
      CANCEL: 'Cancel',
      SELECT_IMAGE: 'Select Image',
      NEW_OPD_CARD: 'New OPD Card',
      PRINT_OPD_CARD: 'Print OPD Card',
      PRINT: 'Print',
      DOWNLOAD: 'Download',
      PREVIEW: 'Preview',
      OPERATE: 'Operate',
      VIEW_DETAIL: 'View Detail',
      SEE_MORE: 'See More',
      SEE_LESS: 'See Less',
      COMPARE_HISTORY: 'Compare History',
      MEDICAL_CERTIFICATE: 'Medical Certificate',
      ADD: 'Add',
      ADD_MEDICINE_LABEL: 'Add Medicine Label',
      EDIT_MEDICINE_LABEL: 'Edit Medicine Label',
      PRINT_MEDICINE_LABEL: 'Print Medicine Label',
      COMPLETE_OPD: 'Complete OPD',
      CONFIRM: 'Confirm',
      CHANGE_PASSWORD: 'Change Password',
      CLOSE: 'Close',
      LOAD_MORE: 'Load More',
      ADD_PHONE: 'Add Phone',
      ADD_HOSPITAL: 'Add Hospital',
      CLEAR: 'Clear',
      APPLY: 'Apply',
      FILTERS: 'Filters',
      CUSTOMER_PATIENT_DETAILS: 'Customer / Patient Details',
      OPEN_OPD: 'Open OPD Card',
      APPOINTMENT: 'Appointment',
      EXAMINATION_FILE: 'Examination File',
      ALL: 'All',
      SELECT_ALL: 'Select All',
      BACK_TO_HOME: 'Back to Home',
      NEW_APPOINTMENT: 'New Appointment',
      BLANK_OPD_CARD: 'Blank OPD Card',
    },
    TABLE: {
      NO_DATA_DISPLAY: 'No data to display',
      NO_DATA: 'No data',
      TOTAL: 'Total',
      SUMMARY_TOTAL: 'Summary Total',
      TOTAL_RESULT: 'Total',
      TOTAL_LIST: '',
      CUSTOMER_PATIENT: 'Customer / Patient',
      AGE: 'Age',
      AGE_YEAR: 'Age (Year)',
      ID: 'ID',
      PHONE: 'Phone',
      EMERGENCY: 'Emergency',
      LINE: 'LINE',
      LAST_UPDATED: 'Last Updated',
      LAST_LOGIN: 'Last Login',
      OPTION: 'Option',
      BY: 'by',
      CONNECTED: 'Connected',
      NOT_CONNECTED: 'Not Connected',
      DOC_NO: 'Doc No.',
      DATE: 'Date',
      OPD_LIST: 'OPD List',
      DIAG_LIST: 'Diag. List',
      RESPONSIBLE_DOCTOR: 'Responsible Doctor',
      SUB_TOTAL: 'Total Price',
      SUB_TOTAL_DESC: 'Include Medicine , Product , Operative and Service',
      NET_PRICE: 'Total Price',
      PAID_PRICE: 'Total',
      STATUS: 'Status',
      PAID: 'Paid',
      VOLUME_NO_DOC_NO: 'Vol No./Doc No.',
      REFERENCE: 'Reference',
      ISSUED_BY: 'Issues by',
      ITEMS: 'Items',
      PRICE_PER_UNIT: 'Price/Unit',
      AMOUNT: 'Amount',
      MIN_AMOUNT: 'Min amount',
      DISCOUNT: 'Discount',
      BAHT: 'Baht',
      MEDICINE_USAGE: 'Medicine Usage',
      VAT: 'Vat. ({{value}})',
      SERVICE_DATE: 'Service Date',
      COMPLETED_DATE: 'Completed Date',
      EXAMINATION_ROOM: 'Examination Room',
      CN: 'CN',
      VN: 'VN',
      ITEMS_SUM: 'Cost of examination and medicine',

      // APPOINTMENT
      APPOINTMENT_DATE: 'Appointment Date',
      APPOINTMENT_TIME: 'Appointment Time',
      APPOINTMENT_DATETIME: 'Appointment Date/Time',
      // REPORT
      RECEIPT_NO: 'Receipt No',
      APPOINTMENT: 'Appointment',

      // SETTING
      CODE: 'Code',
      PRICE: 'Price',
      COURSE_PRICE: 'Course Price',
      UNIT: 'Unit',
      NUMBER_OF_MEDICINE: 'No. of Med.',
      ACCOUNT_NAME: 'Account Name',
      ACCOUNT_NO: 'Account No.',
      USED_FOR: 'Used For',
      NAME: 'Name',
      NAME_CODE: 'Name / Code',
      LICENSE_NO: 'License No.',
      EMAIL: 'Email',
      ROLE: 'Role',
      DESCRIPTION: 'Description',
      GROUP: 'Group',
      CATEGORY: 'Category',
      TYPE: 'Type',
      MEDICINE_ITEM_COUNT: 'Medecine Items Count',
      MEDICINE_CATEGORY: 'Medicine Category',
      MEDICINE_TYPE: 'Medicine Type',
      MEDICINE_NAME: 'Medocine Name',
      DOCTOR: 'Doctor',
      FEE: 'Fee',
      UNIT_NAME: 'Unit Name',
      PRIMARY_UNIT: 'Primary Unit',
      MULTIPILIER: 'Amt./Unt.',
      MEDICINE_EQUIPMENT_PRICE_PER_TIME: 'Med./Equip. (/Time)',
      TIMES: 'Times',
      SELL_PRICE: 'Sell Price',
      USES_AMOUNT_PER_TIME: 'Uses/Times',
      TYPE_OF_SERVICE: 'Type of Service',
      SHOW: 'Show',
      ENTRIES: 'Entries',
      ALLERGIC_DRUG_NAME: 'Allergic Drug Name',
      SYMPTOMS_WHEN_ALLERGIC_TO_DRUGS: 'Symptoms When Allergic',
      ALLERGIC_DRUG_GROUP: 'Allergic Drug Group',
      COURSE_NAME: 'Course Name',

      // EXAMINATION FILE
      EXAMINATION_FILE: 'Examination Files',

      // DASHBOARD
      INCOME: 'Income',
      EXPIRE_DATE: 'Expire Date',

      //SKU_MASTER
      UNIT_PRICE: 'Price/Unit',
      PRODUCT_NAME: 'Product Name',

      CREATED_AT: 'Created Date',

      STOCK: 'Stock',
      DURATION_TIME: 'Duration Time',
      CUSTOMER_NAME: 'Customer Name',

      REMAIN: 'Remain',
      TOTAL_AVAILABLE_AMOUNT: 'Total Available Amount',
      USED_AMOUNT: 'Used',
      AVAILABLE_AMOUNT: 'Available',
      BUY_COURSE_DATE: 'Buy Date',
      EXPIRE_COURSE_DATE: 'Expire Date',
      CASHIER: 'Cashier',
      WAIT_FOR_PAYMENT: 'Wait for payment',
    },
    MISC: {
      COMING_SOON_TITLE: 'We are launching soon',
      COMING_SOON_TEXT: "We're creating something awesome.Please subscribe to get notified when it's ready!",
      NOT_AUTHORIZED: "You currently don't have access to this!",
      NOT_AUTHORIZED_TEXT:
        'It seems your account might have some access restrictions or there could be an issue with your permissions. Please reach out to the administrator for assistance.',
      BILL_FOOTER: 'If you have any questions or encounter issues, please contact',
      VERSION: 'Version',
    },
    DATETIME: {
      MONTH: {
        JAN: 'Jan',
        FEB: 'Feb',
        MAR: 'Mar',
        APR: 'Apr',
        MAY: 'May',
        JUN: 'Jun',
        JUL: 'Jul',
        AUG: 'Aug',
        SEP: 'Sep',
        OCT: 'Oct',
        NOV: 'Nov',
        DEC: 'Dec',
      },
      MONTH_FULL: {
        JAN: 'January',
        FEB: 'February',
        MAR: 'March',
        APR: 'April',
        MAY: 'May',
        JUN: 'June',
        JUL: 'July',
        AUG: 'August',
        SEP: 'September',
        OCT: 'October',
        NOV: 'November',
        DEC: 'December',
      },
    },
    POS: {
      BAHT: 'Baht',
      PAY: 'Pay',
      PAYMENT: 'Payment',
      PAYMENT_SUCCEED: 'Succeed',
      AMOUNT: 'Amount',
      SUMMARY_PRICE: 'Summary price',
      RECEIVED: 'Received',
      CHANGE: 'Money Change',
      ELIGIBLE_HOSPITAL: 'Eligible Hospital',
      BANK_ACCOUNT: 'Bank Account',
      CARD_TYPE: 'Card Type',
      CREDIT_CHARGE: 'Credit Charge',
      PAYMENT_CASH: 'Cash',
      PAYMENT_BANK: 'Bank Transfer',
      PAYMENT_CREDIT_CARD: 'Credit Card',
      PAYMENT_SOCIAL_SECURITY: 'Social Security',
      CUSTOMER: 'Customer',
      LIST: 'List',
      UNIT_PRICE: 'Price/Unit',
      PRICE: 'Price',
      AMOUNT_ITEM: 'Amount',
      TOTAL: 'Total',
      DISCOUNT: 'Discount',
      TOTAL_PRICE: 'Total price',
      VAX: 'VAX',
      PRICE_AFTER_DISCOUNT: 'After Discount',
      OPD_RECEIPT: 'OPD receipt',
      WAIT_FOR_PAYMENT: 'Wait for payment',
      PAID: 'Paid',
      CANCEL: 'Cancel',
      COURSE_AMOUNT: 'Amount',

      COURSE_SELLING: {
        TITLE: 'Course Selling',
        COURSE: {
          TITLE: 'Courses',
          OPEN_PATIENT_COURSE: 'Open Patient Courses',
        },
        PATIENT_COURSE: {
          TITLE: 'Patient Courses',
        },
      },
      DOCTOR_STAFF: 'Doctor and Staff',
      ADD_DOCTOR_STAFF: 'Add Doctor and Staff',
      MANAGE_DOCTOR_STAFF: 'Doctor and Staff',
    },
    DASHBOARD: {
      NEW_PATIENT: 'New Patient',
      APPOINTMENT_CERTIFICATE: 'Appointment Certificate',
      PRINT_APPOINTMENT_CERT: 'Print',
      PAYMENT_LIST: 'Payment List',
      PAY: 'Pay',
      PAYMENT: 'Payment',
      PAYMENT_SUCCEED: 'Payment Succeed',
      EXAMINATION_DOCTOR_FEE: 'Examination Fee / Doctor Fee',
      EXAMINATION_PROCEDURES_FEE: 'Procedure Fee',
      MEDICINE_FEE: 'Medicine Fee',
      EQUIPMENT_FEE: 'Equipment Fee',
      MEDICINE_EQUIPMENT_FEE: 'Medicine Fee/ Equipment Fee',
      TOTAL_CUSTOMER: 'Total Customer',
      TOTAL_NEW_CUSTOMER: 'New Customer',
      TOTAL_EXAM_CUSTOMER: 'Total Examination Customer',
      TOTAL_CARE_CUSTOMER: 'Total Care Customer',
      PAYMENT_SUMMARY: 'Total Payment',
      INCOME_SUMMARY: 'Total Income',
      BY_CATEGORY: 'By Category',
      BY_ITEM: 'By Item',
      TOP_5: 'Top 5',
      DOCTOR_STAFF_INCOME: 'Doctor / Staff income',
      MEDICINE_EQUIPMENT_SOLD: 'Medicine / Equipment Sale',
      EXPIRE_PRODUCT: 'Expire Product / Low Stock',
      START_DATE: 'Start from',
      TO_DATE: 'to',
      GO_BACK: 'Previous',
      TOTAL_SALE: 'Total Sale',
      VIEW_MODE: 'View',
      DATA_DATE: 'Data Date',
      LIST: 'List',
    },
    ROLE_MANAGEMENT: {
      PERMISSION_HEADER: {
        CREATE: 'Create',
        VIEW: 'View',
        EDIT: 'Edit',
        DELETE: 'Delete',
      },
    },
  },
};
